import * as React from "react"
import { ClientsT, PageContextT } from "../types/Common"
import Layout from "../components/organisms/Layout"
import Button from "../components/atoms/Button"
import Icon from "../components/atoms/Icon"
import Container from "../components/atoms/Container"
import { useTranslation } from "react-i18next"
import SEO from "components/seo"

type Props = {
  pageContext: PageContextT
  clients: ClientsT
}

const NotFoundPage: React.ComponentType<Props> = (props: Props) => {
  const {
    pageContext: {
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      configurations,
      mobileHeaderLinks,
      template,
    },
  } = props
  const { t } = useTranslation()

  return (
    <Layout
      mainHeaderLinks={mainHeaderLinks}
      otherHeaderLinks={otherHeaderLinks}
      navigationLinks={navigationLinks}
      footerSections={footerSections}
      socialLinks={socialLinks}
      commonLinks={commonLinks}
      configurations={configurations}
      mobileHeaderLinks={mobileHeaderLinks}
      breadCrumbs={[]}
    >
      <SEO title="404" description={"Stránka nenalezena"} template={template} />
      <Container>
        <div className="px-6 py-8 sm:p-20 flex flex-col items-center">
          <h1 className="text-quantcomBlue flex flex-col items-center mb-8">
            <span className="text-6xl sm:text-8xl font-extrabold mb-6 sm:mb-8">
              404
            </span>
            <span className="flex flex-col sm:flex-row items-center text-2xl text-center">
              <Icon
                icon={"search"}
                size={"text-2xl"}
                className="mb-2 sm:mr-4 sm:mb-0"
              />
              {t("pageNotFound")}
            </span>
          </h1>
          <p className="text-center">{t("pageNotFoundDescription")}</p>
          <Button
            link={"/"}
            className="mt-6"
            iconAnimated
            iconLeft="arrow-left-short"
          >
            {t("backToHomepage")}
          </Button>
        </div>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
